<template>
  <!-- 面试详情==》》个人信息页面 -->
  <div class="viewInfo">
    <van-nav-bar title="个人信息"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="content">
      <!-- 基本信息 -->
      <div class="baseInfo">
        <!-- 面试人基本信息 -->
        <div class="infoCard">
          <div class="zp">
            <svg-icon style="width:100%;height:100%;"
                      icon-class="default_avator" />
          </div>
          <div class="up">
            <p>{{ baseInfo.empname }}</p>
            <p>
              <span>{{ baseInfo.workyear }}年工作经验</span>
              <span>{{ baseInfo.degree }}</span>
              <span>{{ baseInfo.age }}岁</span>
            </p>
            <p>{{ baseInfo.reqjob }}</p>
          </div>
          <div class="down">
            <span>{{ baseInfo.jobstatus }}</span>
          </div>
        </div>
        <!-- 招聘人信息 -->
        <div class="recruitInfo">
          <p>基本信息</p>
          <van-row>
            <van-col span="8"><span>手机号</span></van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.telephone }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>电子邮箱</span></van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.email }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>现居住地</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.homeaddress }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>最高学历</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.degree }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>毕业院校</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.school }}</span>
            </van-col>
          </van-row>
          <van-row v-if="RsWorkHis.length > 0">
            <van-col span="8"><span>最近工作单位</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ RsWorkHis[0].company }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8"><span>期望薪资</span></van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.reqmoney }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 个人优势 -->
      <van-collapse v-model="activeNames">
        <van-collapse-item title="教育经历"
                           name="1">
          <div class="education"
               v-for="(edItem, edIdx) in RsEduHis"
               :key="edIdx">
            <div class="ed_header">
              <div class="ed_hl">
                <span class="circle"></span>
                <span>{{ edItem.edubt }}</span> ——<span>{{ edItem.eduet }}</span>
              </div>
            </div>
            <div class="ed_hr"
                 style="margin-bottom: 0.24rem"
                 v-if="edItem.edudegree">
              专业：{{ edItem.edudegree }}
            </div>
            <div class="ed_body"
                 style="margin-bottom: 0">
              <div class="ed_bl"
                   style="padding-left: 0.5rem">
                <span v-show="edItem.edumajor">{{ edItem.edumajor }}</span>
                <span>{{ edItem.eduorg }}</span>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title="工作经历"
                           name="2">
          <div class="workHistory"
               v-for="(workItem, wIdx) in RsWorkHis"
               :key="wIdx">
            <div class="workgs">
              <span class="circle"></span>
              <span>{{ workItem.company }}</span>
            </div>

            <div class="work_body">
              <div class="work_head">
                <div class="whl"
                     style="
                    max-width: 48%;
                    padding-right: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  ">
                  <span>{{ workItem.station }}</span>
                </div>
                <div class="whr">
                  <span style="font-size: 0.36rem">
                    {{ workItem.workbt }} -
                    {{ workItem.worket ? workItem.worket : "现在" }}
                  </span>
                </div>
              </div>
              <span>工作内容：</span>
              <p style="text-align: justify">
                {{ workItem.workdesc }}
              </p>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title="自我评价"
                           name="3">
          <ul>
            <li style="text-align: justify">
              {{ baseInfo.selfdesp }}
            </li>
          </ul>
        </van-collapse-item>
        <van-collapse-item title="面试记录"
                           name="4">
          <div class="baseInfo"
               style="
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;
              padding-bottom:0;
              border-bottom:1px solid #ccc;
            "
               v-for="(value,key) in RsIV"
               :key="key">
            <div class="recruitInfo">
              <van-row>
                <van-col span="8">
                  <span>轮次</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span style="color: #2b8df0">第{{ value.iv_times }}轮</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8"><span>面试时间</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_date }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.iv_res">
                <van-col span="8"><span>面试结果</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_res }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8"><span>主面试官</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.empname }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode1">
                <van-col span="8"><span>副面试官1</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode1 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode2">
                <van-col span="8"><span>副面试官2</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode2 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode3">
                <van-col span="8"><span>副面试官3</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode3 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode4">
                <van-col span="8"><span>副面试官4</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode4 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode5">
                <van-col span="8"><span>副面试官5</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode5 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.iv_pj">
                <van-col span="8"><span>面试评价</span></van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_pj }}</span>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- 底部 -->
      <div class="viewFooter">
        <span @click="configRz">确认入职</span>
        <span @click="zitoElimination">放弃入职</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getResumeDetail } from "@api/wxzp.js";
export default {
  data () {
    return {
      footerType: "",
      show: false,
      baseInfo: {},
      RsWorkHis: [],
      activeNames: [],
      RsEduHis: [],
      url: "",
      RsIV: []
    };
  },
  methods: {
    onClickLeft () {
      this.$router.push("/toInduction");
    },
    configRz () {
      this.$router.push({
        path: "/confirmEntry",
        query: {
          autoid: this.$route.query.autoid,
          stationid: this.$route.query.stationid,
          returnPath: "toInduction"
        }
      });
    },
    zitoElimination () {
      this.$router.push({
        path: "/giveUpEntry",
        query: {
          autoid: this.$route.query.autoid,
          stationid: this.$route.query.stationid,
          returnPath: "giveUpEntry"
        },
      });
    },
    onClickRight () { },
    closeEwm () {
      this.show = false;
    },
    getResumeDetailData () {
      getResumeDetail({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then((res) => {
        // console.log(res, "getResumeDetailData");
        // 基本信息
        this.baseInfo = res.ResumeInfo[0];
        // 工作经验
        this.RsWorkHis = res.RsWorkHis;
        // 教育经验
        this.RsEduHis = res.RsEduHis;
        // 面试记录
        this.RsIV = res.RsIV;
      });
    },
  },
  created () {
    this.getResumeDetailData();
  }
};
</script>

<style lang="less" scoped>
.viewInfo {
  position: relative;
  height: 100vh;
  // overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 232px);
    overflow: auto;
    box-sizing: border-box;
    .baseInfo {
      box-sizing: border-box;
      padding: 26px 30px;
      background: #fff;
      // margin-bottom: 24px;
      .infoCard {
        position: relative;
        width: 690px;
        height: 308px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        opacity: 1;
        border-radius: 16px;
        padding-left: 36px;
        padding-right: 32px;
        margin-bottom: 42px;
        .zp {
          position: absolute;
          width: 132px;
          height: 132px;
          right: 32px;
          top: 52px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .up {
          border-bottom: 1px solid #ccc;
          padding: 42px 140px 26px 0px;
          box-sizing: border-box;
          p {
            margin: 0;
          }
          p:first-child {
            font-size: 52px;
            height: 52px;
            line-height: 52px;
            font-family: Source Han Sans CN;
            color: #000;
            margin-bottom: 16px;
          }
          p:nth-child(2) {
            font-size: 28px;
            line-height: 28px;
            font-family: Source Han Sans CN;
            color: #666;
            margin-bottom: 12px;
            span:first-child,
            span:nth-child(2) {
              border-right: 1px solid #999999;
            }
            span {
              padding: 0 14px;
            }
            span:first-child {
              padding-left: 0;
            }
          }
          p:nth-child(3) {
            line-height: 36px;
            font-size: 30px;
            font-family: Source Han Sans CN;
          }
        }
        .down {
          height: 84px;
          line-height: 84px;
          box-sizing: border-box;
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
        }
      }
      .recruitInfo {
        p {
          font-size: 36px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .van-row {
          margin-bottom: 40px;
          .van-col:first-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #999;
          }
          .van-col:last-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #333;
          }
        }
      }
    }
    .van-collapse {
      /deep/.van-collapse-item {
        .van-cell {
          .van-cell__title {
            span {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #000000;
              opacity: 1;
            }
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            .education {
              .ed_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666;
                font-size: 32px;
                font-family: Source Han Sans CN;
                margin-bottom: 18px;
                .ed_hl {
                  display: flex;
                  align-items: center;
                  .circle {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 30px;
                    height: 30px;
                    background: #ffffff;
                    border: 6px solid #2b8df0;
                    border-radius: 50%;
                    margin-right: 16px;
                  }
                  span {
                  }
                }
                .ed_hr {
                }
              }
              .ed_body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                border-left: 1px solid #ccc;
                margin-bottom: 18px;
                margin-left: 14px;
                .ed_bl {
                  box-sizing: border-box;
                  font-family: Source Han Sans CN;
                  padding-left: 66px;
                  span:first-child {
                    display: inline-block;
                    width: 60px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    border: 2px solid #2b8df0;
                    border-radius: 8px;
                    color: #2b8df0;
                    font-size: 24px;
                    margin-right: 16px;
                  }
                  span:last-child {
                    font-size: 32px;
                    color: #333333;
                  }
                }
                .ed_br {
                  font-size: 32px;
                  color: #333333;
                }
              }
            }
            .workHistory {
              .workgs {
                display: flex;
                align-items: center;

                .circle {
                  display: inline-block;
                  box-sizing: border-box;
                  width: 30px;
                  height: 30px;
                  background: #ffffff;
                  border: 6px solid #2b8df0;
                  border-radius: 50%;
                  margin-right: 16px;
                }
                span:last-child {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #000;
                }
              }
              .work_body {
                padding: 40px 0px;
                padding-left: 30px;
                border-left: 1px solid #ccc;
                margin-left: 14px;
                box-sizing: border-box;
                font-size: 32px;

                font-family: Source Han Sans CN;
                color: #333333;
                .work_head {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 30px;
                }

                span {
                }
                p {
                  margin: 0;
                }
              }
            }
            ul {
              li {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
}
.viewFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 80px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2b8df0;
    text-align: center;
  }
  span:first-child {
    flex: 1;
    border-right: 1px solid #ccc;
  }
  span:nth-child(2) {
    flex: 1;
    // border-right:1px solid #ccc;
  }
  .van-dropdown-menu {
    flex: 1;
    /deep/ .van-ellipsis {
      font-size: 36px;
      color: #2b8df0;
    }
    /deep/.van-dropdown-menu__title--down::after {
    }
  }
}
</style>
